import React, { useState } from 'react'
import { Modal, Form, Input, Select, Button, Row, Col, DatePicker, message } from 'antd';
import { PostAPI } from '../../../../../redux';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import {
  RiArrowLeftCircleFill
} from 'react-icons/ri';
import TablePage from '../../../../../components/pages/TablePage';
const { Option } = Select;

export default ({ isModalOpen, handleCancel, awb, setRefresh }) => { // eslint-disable-line

  const dispatch = useDispatch()
  const [dataDocument, setDataDocument] = useState(null)
  const [refresh, setRefreshTable] = useState(moment().unix());
  const [docType, setDocType] = useState(null)
  const [idDoc, setIdDoc] = useState(null)
  const [form] = Form.useForm();

  console.log(dataDocument)

  const closeModal = () => {
    setDataDocument(null)
    setDocType(null)
    setIdDoc(null)
    handleCancel()
    form.resetFields();
  }

  const findDocument = (value) => {
    dispatch(PostAPI({ url: 'api/v1/verify/export_verify', data: value }))
      .then(resp => {
        const { no_npe } = resp?.payload;
        if (no_npe) {
          setDataDocument({ ...resp.payload, doc_type: value?.current_kd_dok })
        } else {
          setDataDocument(null)
          message.error('Tidak Menemukan Data');
        }
      })
  }

  const saveDocument = () => {
    const { doc_type, doc_no, no_npe, tgl_npe, npwp_eks, nama_eks,
      kode_kemas, tgl_daftar, no_daftar } = dataDocument;
    dispatch(PostAPI({
      url: 'api/v1/verify/export_verify_save', data: {
        _id: awb?._id, id_doc: idDoc, doc_type,
        doc_no, no_npe, tgl_npe, npwp_eks, nama_eks,
        kode_kemas, tgl_daftar, no_daftar
      }
    }))
      .then(resp => {
        if (resp.payload) {
          setRefresh(moment().unix())
          handleCancel()
          closeModal()
          message.success('berhasil verifikasi');
        }
      })
  }

  const saveVerify = () => {
    form.validateFields()
      .then((values) => {
        const { current_kd_dok, doc_date, no_dok_inout, exportir_npwp } = values;
        dispatch(PostAPI({
          url: 'api/v1/verify/doc_save', data: {
            doc_type: current_kd_dok,
            doc_date: doc_date || '',
            doc_code: '',
            no_doc: no_dok_inout,
            no_npwp: exportir_npwp,
            exp_imp: 'export',
          }
        }))
          .then(resp => {
            if (resp.payload) {
              setRefreshTable(moment().unix());
              form.resetFields();
              message.success('berhasil save');
            }
          })
      })
  }

  const handleChange = (value) => {
    setDocType(value)
  };

  const columns = [
    {
      name: "Action",
      id: '_id',
      width: '100px',
      selector: (row) => {
        const { _id, doc_type, no_doc, no_npwp, doc_date } = row;
        return (
          <RiArrowLeftCircleFill size={20} style={{ color: 'green', cursor: 'pointer' }}
            onClick={() => {
              if (form) {
                form.setFieldsValue({
                  current_kd_dok: doc_type,
                  no_dok_inout: no_doc,
                  exportir_npwp: no_npwp,
                  doc_date: moment(doc_date, 'YYYY-MM-DD')
                });
                handleChange(doc_type)
                setIdDoc(_id)
              }
            }}
          />
        )
      }
    },
    {
      selector: (row) => row?.no_doc,
      name: "No Doc",
      id: 'no_doc',
      width: '200px',

    },
    {
      selector: (row) => row?.doc_type,
      name: "Doc Type",
      id: 'doc_type'
    }
  ]

  return (
    <div>
      <Modal title={`Verify AWB ${awb?.mawb_number}`} visible={isModalOpen} onCancel={closeModal} footer={null} width={1200}>
        <Row justify="space-around">
          <Col span={9}>
            <Form
              layout='vertical'
              onFinish={findDocument}
              form={form}
            >
              <Form.Item
                name="current_kd_dok"
                label="Type Document"
                rules={[
                  {
                    required: true,
                    message: "Type Document wajib di isi"
                  },
                ]}
              >
                <Select
                  allowClear
                  onChange={handleChange}
                >
                  <Option value="NPE">NPE</Option>
                  <Option value="PKBE" disabled>PKBE</Option>
                  <Option value="P3BET">P3BET</Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="no_dok_inout"
                label="No Document"
                rules={[
                  {
                    required: true,
                    message: "No Document wajib di isi"
                  },
                ]}
              >
                <Input autoComplete='off' />
              </Form.Item>
              {docType === "P3BET" ?
                <Form.Item
                  name="doc_date"
                  label="Tanggal Doc"
                  rules={[
                    {
                      required: true,
                      message: "Tanggal Doc wajib di isi"
                    },
                  ]}
                >
                  <DatePicker style={{ width: '100%' }} placeholder="" />
                </Form.Item> :
                <Form.Item
                  name="exportir_npwp"
                  label="NPWP"
                  rules={[
                    {
                      required: true,
                      message: "NPWP wajib di isi"
                    },
                  ]}
                >
                  <Input autoComplete='off' />
                </Form.Item>
              }
              <Form.Item style={{ marginTop: 60 }}>
                <Button type="primary" htmlType="submit">
                  Verify Document
                </Button>
                <Button type="dashed" style={{ marginLeft: 10 }} onClick={() => saveVerify()}>
                  Save
                </Button>
                <Button type="dashed" style={{ marginLeft: 10 }} onClick={() => {
                  setDataDocument(null)
                  setIdDoc(null)
                  form.resetFields()
                }}>
                  Clear
                </Button>
              </Form.Item>
            </Form>
          </Col>
          {dataDocument === null ?
            <Col span={14}>
              <TablePage
                url="api/v1/verify/verify_doc/export"
                columns={columns}
                refresh={refresh}
                title={"Export Verify"}
              />
            </Col> :
            <Col span={14} >
              <table style={{ marginLeft: 30, marginTop: 30 }}>
                <tr>
                  <td style={{ width: 100 }}>Kd Dok</td>
                  <td>: {dataDocument?.doc_no}</td>
                </tr>
                <tr>
                  <td style={{ width: 100 }}>No NPE</td>
                  <td>: {dataDocument?.no_npe}</td>
                </tr>
                <tr>
                  <td style={{ width: 100 }}>Tgl NPE</td>
                  <td>: {dataDocument?.tgl_npe}</td>
                </tr>
                <tr>
                  <td style={{ width: 100 }}>No Daftar</td>
                  <td>: {dataDocument?.no_daftar}</td>
                </tr>
                <tr>
                  <td style={{ width: 100 }}>Tgl Daftar</td>
                  <td>: {dataDocument?.tgl_daftar}</td>
                </tr>
                <tr>
                  <td style={{ width: 100 }}>Nama Eks</td>
                  <td>: {dataDocument?.nama_eks}</td>
                </tr>
                <tr>
                  <td style={{ width: 100 }}>NPWP Eks</td>
                  <td>: {dataDocument?.npwp_eks}</td>
                </tr>
              </table>
              <Button type="primary" style={{ marginLeft: 32, width: '90%', marginTop: 10 }} onClick={() => saveDocument()}>
                Save
              </Button>
            </Col>
          }
        </Row>
      </Modal>
    </div>
  )
}