import React, { useState } from 'react';
import TablePage from '../../../../../components/pages/TablePage';
import DetailPage from './detail';
import moment from 'moment';
import { RiDvdFill } from "react-icons/ri";
import { useDispatch } from 'react-redux';
import { PostAPI } from '../../../../../redux';

export default () => {

    const dispatch = useDispatch();
    const [refresh, setRefresh] = useState(moment().unix())

    const handleResend = (data) => {
        const url = 'api/v1/tps_logs/resend'
        dispatch(PostAPI({ url, data })).then(resp => {
            setRefresh(moment().unix())
        })
    }

    const columns = [
        {
            selector: 'createdAt',
            name: 'DATE',
            wrap: true,
            width: '150px',
            format: ({ createdAt }) => moment(createdAt).format('DD-MMM-YYYY HH:mm:ss').toUpperCase()
        },
        {
            selector: 'origin',
            name: 'ORIGIN',
            wrap: true,
            width: '100px',
        },
        {
            selector: 'destination',
            name: 'DESTINATION',
            wrap: true,
            format: ({ destination }) => destination && destination.join(' ')
        },
        {
            selector: 'smi',
            name: 'SMI',
            width: '80px',
            wrap: true,
        },
        {
            selector: 'msgid',
            name: 'SUBJECT',
            wrap: true,
        },
        {
            selector: 'priority',
            name: 'PRIORITY',
            width: '90px',
            wrap: true,
            format: ({ priority }) => priority?.code?.toUpperCase() === 'QU' ? <span style={{ color: 'red', fontWeight: 'bold' }}>HIGH</span> :
                (priority?.code?.toUpperCase() === 'QK' ? <span style={{ color: 'red' }}>NORMAL</span> : <span style={{ color: 'green' }}>LOW</span>)
        },
        {
            selector: 'corid',
            name: 'CORID',
            width: '90px',
            wrap: true,
        },
    ]

    return (
        <TablePage
            title={"Telex"}
            url="api/v1/telex"
            columns={columns}
            ExpandComponent={DetailPage}
            refresh={refresh}
        />
    )
}