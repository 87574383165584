import React from 'react'
import TablePage from '../../../../../components/pages/TablePage'
import moment from 'moment'
export default () => {
    const columns=[
        {
            selector:({origin, dest})=>`${origin?.code } - ${dest?.code}`,
            name:"Routes",
            id:'routes',
            width:'120px'
        },
        {
            selector:(row)=>row?.mawb || row?.awb_id?.awb_number,
            name:"MAWB",
            id:'awb_number',
            width:'150px'
        },
        {
            selector:(row)=>row?.house_number,
            name:"HAWB",
            id:'hawb_number',
            width:'150px'
        },
        {
            selector:({commodity})=>commodity,
            name:"Commodity",
            id:'commodity',
            width:'150px'
        },
        {
            selector:({desc})=>desc || '-',
            name:"Desc",
            id:'desc',
            width:'150px'
        },
        {
            selector:({shipper})=>shipper?.name,
            name:"Shipper",
            id:'shipper',
            width:'150px'
        },
        {
            selector:({consignee})=>consignee?.name,
            name:"Consignee",
            id:'consignee',
            width:'150px'
        },
        {
            selector:({shipment_detail})=>`${shipment_detail?.weight}/${shipment_detail?.pieces}`,
            name:"Weight/Total",
            id:'wt',
            width:'100px'
        },
        {
            selector:(row)=>(row?.createdAt && moment(row.createdAt).format('DD-MMM-YYYY HH:mm')) || '-',
            name:"CreatedAt",
            id:'created_at'
        },
    ]
    return (
        <TablePage
            title={"Airwaybill House"}
            url="api/v1/hawb"
            columns={columns}            
        />
    )
}