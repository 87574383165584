import React from 'react';
import { useFetch } from '../../../../../../hooks/useFetch';
import PrintBrcImp from './printBarcode';
import { RiPrinterFill } from "react-icons/ri";

export default ({ data }) => {
    const { _id } = data;
    const [rows, loading] = useFetch(`api/v1/tps_import/airwaybill/${_id}`);
    return (
        <div style={{ display: 'flex', flexDirection: 'column', padding: 32 }}>
            <table style={{ border: '1px solid black' }}>
                <tr style={{ border: '1px solid black' }}>
                    <th style={{ border: '1px solid black', fontSize: 11 }}>Awb Number</th>
                    <th style={{ border: '1px solid black', fontSize: 11 }}>Hawb Number</th>
                    <th style={{ border: '1px solid black', fontSize: 11 }}>BC 11</th>
                    <th style={{ border: '1px solid black', fontSize: 11 }}>Pieces</th>
                    <th style={{ border: '1px solid black', fontSize: 11 }}>Weight</th>
                    <th style={{ border: '1px solid black', fontSize: 11 }}>Print Barcode</th>
                </tr>
                {rows && Array.isArray(rows) && rows.map((item, idx) => (
                    <>
                        <tr style={{ border: '1px solid black' }}>
                            <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{item?.mawb_number}</td>
                            <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{item?.hawb_number}</td>
                            <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{item?.no_bc11}</td>
                            <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{item?.jml_kemas}</td>
                            <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{item?.bruto}</td>
                            <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center", width: 25 }}>
                                <PrintBrcImp
                                    data={item}
                                    title="Print Barcode"
                                    icon={<RiPrinterFill />}
                                    iconBtnProps={{
                                        size: "small",
                                    }}
                                />
                            </td>

                        </tr>
                    </>
                ))}
            </table>
        </div>
    )
}