import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Dashboard from "./dashboard";

import AutogateExportIn from "./autogate";
import AutogateExportOut from "./autogate/out";

import AutogateImportIn from "./autogate_import";
import AutogateImporOut from "./autogate_import/out";

import AutogateDistriIn from "./autogate_distribusi";
import AutogateDistriOut from "./autogate_distribusi/out";

import AutogateDistri from "./autogate_distribusi/distribusi";

import Notfound from "../notfound";

export default () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact>
          <Redirect to="/dashboard" />
        </Route>
        <Route path="/dashboard">
          <Dashboard />
        </Route>
        <Route path="/autogate/exportin">
          <AutogateExportIn />
        </Route>
        <Route path="/autogate/exportout">
          <AutogateExportOut />
        </Route>
        <Route path="/autogate/importin">
          <AutogateImportIn />
        </Route>
        <Route path="/autogate/importout">
          <AutogateImporOut />
        </Route>
        <Route path="/autogate/distribusiin">
          <AutogateDistriIn />
        </Route>
        <Route path="/autogate/distribusiout">
          <AutogateDistriOut />
        </Route>
        <Route path="/autogate/distribusi">
          <AutogateDistri />
        </Route>
        <Route path="/*">
          <Notfound />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};
