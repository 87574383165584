import React from 'react';
import moment from 'moment';
import TablePage from '../../../../../../components/pages/TablePage';

export default () => {
    const columns = [
        {
            selector: (row) => row?.mawb_number,
            name: "MAWB Number",
            id: 'mawb_number'
        },
        {
            selector: (row) => row?.hawb_number,
            name: "HAWB Number",
            id: 'hawb_number'
        },
        {
            selector: (row) => <span>{row?.awb_id?.origin?.code} - {row?.awb_id?.dest?.code}</span>,
            name: "Route",
            id: 'state'
        },
        {
            selector: (row) => row?.awb_id?.flight_number,
            name: "Flight Number",
            id: 'flight_number'
        },
        {
            selector: (row) => row?.awb_id?.flight_date,
            name: "Flight Date",
            id: 'flight_date'
        },
        {
            selector: (row) => row?.exportir_npwp,
            name: "NPWP",
            id: 'exportir_npwp'
        },
        {
            selector: (row) => row?.exportir_name,
            name: "Exportir Name",
            id: 'exportir_name',
            wrap: true,
        },
        {
            selector: (row) => row?.no_dok_inout,
            name: "No Document",
            id: 'no_dok_inout',
            wrap: true,
        },
        {
            selector: (row) => row?.tgl_dok_inout,
            name: "Document Date",
            id: 'tgl_dok_inout'
        },
        {
            selector: (row) => (row?.createdAt && moment(row.createdAt).format('DD-MMM-YYYY HH:mm')) || '-',
            name: "Created Date",
            id: 'created_at'
        },
    ]
    return (
        <TablePage
            title={"Export In"}
            url="api/v1/tps_export/export_in"
            actionPosition={'right'}
            editPath={"/tps/export/export_in/form"}
            columns={columns}
        />
    )
}