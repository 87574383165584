
import React, { useEffect } from 'react'
import { Row, Col, Typography, Avatar, Result } from 'antd';
import imageBea from '../../assets/bea-cukai.png';
import imagePt from '../../assets/unex_logo.png';
import Clock from './clock';
import { Helmet } from "react-helmet";
import { useAutogate } from '../../hooks/useAutogate';
import moment from 'moment';
import { ClockCircleOutlined } from '@ant-design/icons';
import useSpeech from '../../hooks/useSpeech';
const { Text } = Typography;

export default () => {
    const { atgData } = useAutogate('exportin');
    const { onSpeak } = useSpeech()

    useEffect(() => {
        {
            atgData?.awb &&
                onSpeak(
                    `Airwaibill ${atgData?.awb} Telah Masuk Gate`
                );
        }
    }, [atgData]); // eslint-disable-line

    return (
        <div>
            <Helmet>
                <title>TPS Online - Gate Export In</title>
            </Helmet>
            <Row type="flex" justify="space-around" align="middle">
                <Col>
                    <img src={imageBea} alt="My Image" width={100} />
                </Col>
                <Col>
                    <div style={{ textAlign: 'center' }}>
                        <h2 style={{ fontSize: 30 }}>GATE EXPORT IN</h2>
                        <span>Auto Gate System | Area Terminal Kargo</span> <br />
                        <Clock />
                    </div>
                </Col>
                <Col>
                    <img src={imagePt} alt="My Image" width={100} />
                </Col>
            </Row>

            <Row type="flex">
                <Col span={24}>
                    {!atgData?.awb ?
                        <div style={{ height: "calc(100vh - 106px)", overflow: 'auto', backgroundColor: "#cccccc", display: 'flex', alignItems: "center", justifyContent: 'center' }}>
                            <Result
                                icon={<ClockCircleOutlined />}
                                title="Belum Ada Data"
                            />
                        </div> :
                        <>
                            <div style={{ position: 'absolute', width: '100%' }}>
                                <Row style={{ marginTop: 20 }}>
                                    <Col span={12} style={{ textAlign: 'left' }}>
                                        <Text style={{ marginLeft: 10, fontSize: 40, color: "white", marginLeft: 20 }}>{atgData?.awb}/{atgData?.hawb}</Text>
                                    </Col>
                                    <Col span={12} style={{ textAlign: 'right' }}>
                                        <Avatar style={{ marginRight: 30, backgroundColor: 'white', padding: 15 }} src={`/images/airline_logo/${atgData?.airline_code}.png`} size={80} />
                                    </Col>
                                </Row>
                            </div>
                            <div style={{ height: "calc(100vh - 106px)", overflow: 'auto', backgroundColor: `#389e0d`, display: 'flex', alignItems: "center", justifyContent: 'center' }}>
                                <div style={{ display: 'block', textAlign: 'center', color: 'white' }}>
                                    <h1 style={{ color: 'white', fontSize: 90 }}>{atgData?.doc}</h1>
                                    <h1 style={{ color: 'white', fontSize: 50 }}>{atgData?.time_send !== "-" ? moment(atgData?.tgl_doc).format("DD-MMM-YYYY") : "-"}</h1>
                                    <h1 style={{ color: 'white', fontSize: 50 }}>{atgData?.exportir}</h1>
                                    <h1 style={{ color: 'white', fontSize: 30 }}>{atgData?.time_send !== "-" ? `Waktu Kirim : ${atgData?.time_send}` : "-"}</h1>
                                </div>
                            </div>
                        </>
                    }
                </Col>
            </Row>
        </div >
    )
}