import React from 'react'
import TablePage from '../../../../../components/pages/TablePage'
import moment from 'moment'
import { FiCopy } from "react-icons/fi";
import { Button } from 'antd'
import { useHistory } from 'react-router-dom'
import DetailPage from './detail';

export default () => {
    const history = useHistory();

    const columns = [
        {
            selector: (row) => row?.flight_number,
            name: "Flight Number",
            id: 'flight_number'
        },
        {
            selector: (row) => row?.mawb_number,
            name: "Awb Number",
            id: 'awb_number'
        },
        {
            selector: (row) => row?.jml_kemas,
            name: "Pieces",
            id: 'jml_kemas'
        },
        {
            selector: (row) => row?.bruto,
            name: "Weight",
            id: 'bruto'
        },
        {
            selector: (row) => row?.origin.code,
            name: "Origin",
            id: 'origin'
        },
        {
            selector: (row) => row?.dest.code,
            name: "Dest",
            id: 'dest'
        },
        {
            selector: (row) => (row?.createdAt && moment(row.createdAt).format('DD-MMM-YYYY HH:mm')) || '-',
            name: "Created At",
            id: 'created_at'
        },
        {
            name: "Action",
            id: 'Action',
            selector: (row) => {
                return (
                    <Button
                        icon={<FiCopy size={18} color="black" />}
                        style={{ paddingInline: 8 }}
                        shape={'round'}
                        type="link"
                        onClick={() => history.push({ pathname: '/airwaybill/distribusi/hawbform', state: { ...row, isCreate: false } })}
                    />
                )
            },
        },
    ]
    return (
        <TablePage
            title={"Distribusi Airwaybill"}
            url="api/v1/distribusi_awb"
            actionPosition={'first'}
            columns={columns}
            createPath="/airwaybill/distribusi/form"
            ExpandComponent={DetailPage}
        />
    )
}