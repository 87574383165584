import React from 'react'
import TablePage from '../../../../../components/pages/TablePage'
import moment from 'moment'
export default () => {
    const columns=[
        {
            selector:({origin, dest})=>`${origin?.code } - ${dest?.code}`,
            name:"Routes",
            id:'routes'
        },
        {
            selector:(row)=>row?.awb_number,
            name:"AWB Number",
            id:'awb_number'
        },
        {
            selector:({product_desc})=>product_desc || '-',
            name:"Desc",
            id:'desc'
        },
        {
            selector:({flight_details})=>flight_details[0]?.flight_number,
            name:"Flight Number",
            id:'flight_number'
        },
        {
            selector:({shipper})=>shipper?.name,
            name:"Shipper",
            id:'shipper'
        },
        {
            selector:({consigne})=>consigne?.name,
            name:"Consignee",
            id:'consignee'
        },
        {
            selector:({shipment_detail})=>`${shipment_detail?.kilo}/${shipment_detail?.total}`,
            name:"Weight/Total",
            id:'wt'
        },
        {
            selector:(row)=>(row?.createdAt && moment(row.createdAt).format('DD-MMM-YYYY HH:mm')) || '-',
            name:"CreatedAt",
            id:'created_at'
        },
    ]
    return (
        <TablePage
            title={"Airwaybill Master"}
            url="api/v1/awb"
            columns={columns}            
        />
    )
}