import React from 'react';
import ReportDaily from "../../../../../components/pages/reportDaily";
import { useFetch } from '../../../../../hooks/useFetch';

export default () => {
    return (
        <ReportDaily
            title={'Export Reports'}
            repType={'airwaybill'}
            url={"/api/v1/report_export"}
            FetchComponent={useFetch}
        />
    )
}