import React from 'react';
import TablePage from '../../../../../../components/pages/TablePage';
import Awbs from './awbs';

export default () => { // eslint-disable-line
    const columns = [
        {
            selector: (row) => row?.flight_number,
            name: "flight number"
        },
        {
            selector: (row) => row?.flight_date,
            name: "flight date"
        }
    ]
    return (
        <TablePage
            title={"Import Out"}
            url="api/v1/tps_import/import_out"
            actionPosition={'right'}
            columns={columns}
            ExpandComponent={Awbs}
        />
    )
}