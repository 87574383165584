import { Button, Divider, Form, Typography } from 'antd'
import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { SaveOutlined, ArrowLeftOutlined, SendOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { PostAPI } from '../../redux'
import CONFIG from '../../config/env';
import { toast } from 'react-toastify';
import { useRef } from 'react'

const { Title } = CONFIG;

export default ({ url, model, setModel, onEditFormEvent, title, children, callbackPath,
    formSize, labelCol, wrapperCol, onSaveEvent,
    emptyModel, onClearModel }) => {
    const location = useLocation()
    const [initOke, setInitOke] = useState(false);
    const formRef = useRef();

    useEffect(() => {
        if (!!location.state) {
            const locState = typeof onEditFormEvent === 'function' ? onEditFormEvent(location.state) : location.state
            setModel({ ...model, ...locState });
        }
        setInitOke(true);
    }, [location.state])
    useEffect(() => {
        document.title = `${Title} (${title})`;
    }, [])

    const dispatch = useDispatch();
    const history = useHistory();

    const SaveData = () => {
        formRef.current.validateFields().then((values) => {
            const m = { ...model, ...values };
            const data = (typeof onSaveEvent === 'function' && onSaveEvent(m)) || m;
            const urls = `${url}/save`;
            dispatch(PostAPI({ url: urls, data, callbackPath })).then(resp => {
                if (!!resp?.payload) {
                    if (!!callbackPath) {
                        history.push({ pathname: callbackPath })
                    }
                    else {
                        setInitOke(false);
                        const m = (!!onClearModel && typeof onClearModel === 'function' && onClearModel(model)) || emptyModel;
                        setModel(m);
                        setTimeout(() => {
                            setInitOke(true);
                        }, 50)
                    }
                    toast.success("Data Saved!");
                }
            })
        });
    };

    const sendData = (values) => {
        const m = { ...model, ...values };
        const data = (typeof onSaveEvent === 'function' && onSaveEvent(m)) || m;
        dispatch(PostAPI({ url, data, callbackPath })).then(resp => {
            if (!!resp?.payload) {
                if (!!callbackPath) {
                    history.push({ pathname: callbackPath })
                }
                else {
                    setInitOke(false);
                    const m = (!!onClearModel && typeof onClearModel === 'function' && onClearModel(model)) || emptyModel;
                    setModel(m);
                    setTimeout(() => {
                        setInitOke(true);
                    }, 50)
                }
                toast.success("Data Saved!");
            }
        })
    }
    const initValue = useMemo(() => {
        return model;
    }, [model])

    return initOke && (
        <Fragment>
            <Typography.Title level={4}>{model?.isCreate ? 'Create' : 'Edit'} {title}</Typography.Title>
            <Divider style={{ marginBlock: 16 }} />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Form name={title} size={formSize || 'middle'}
                    ref={formRef}
                    onFinish={sendData}
                    autoComplete="off"
                    labelCol={labelCol}
                    wrapperCol={wrapperCol}
                    layout={'vertical'}
                    initialValues={{ ...initValue }}>
                    {children}
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginBlock: 16 }}>
                        <Form.Item label=" " colon={false} noStyle>
                            <Button size='large' style={{ marginInline: 16 }} icon={<SaveOutlined />} type="primary"
                                onClick={SaveData}
                            >
                                Save
                            </Button>
                            <Button size='large' style={{ marginInline: 16 }} icon={<SendOutlined />} type="primary" htmlType='submit' >
                                Send
                            </Button>
                            <Button size='large' icon={<ArrowLeftOutlined />} type="primary" danger onClick={() => history.goBack()} >
                                Cancel
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </div>
        </Fragment>
    )
}