import React, { Fragment } from 'react'
import TablePage from '../../../../../../components/pages/TablePage';
import moment from 'moment';
import { FiCopy, FiEdit } from "react-icons/fi";
import { Button, Tabs } from 'antd'
import Report from './report';
import { useHistory } from 'react-router-dom'
import DetailPage from './detail';

export default () => {
    const history = useHistory();

    const columns = [
        {
            selector: (row) => (row?.createdAt && moment(row.createdAt).format('DD-MMM-YYYY')) || '-',
            name: "Tanggal",
            id: 'createdAt'
        },
        {
            selector: (row) => row?.flight_number,
            name: "Flight Number",
            id: 'flight_number'
        },
        {
            selector: (row) => row?.mawb_number,
            name: "Awb Number",
            id: 'awb_number'
        },
        {
            selector: (row) => row?.jml_kemas,
            name: "Pieces",
            id: 'jml_kemas'
        },
        {
            selector: (row) => row?.bruto,
            name: "Weight",
            id: 'bruto'
        },
        {
            selector: (row) => row?.origin.code,
            name: "Origin",
            id: 'origin'
        },
        {
            selector: (row) => row?.dest.code,
            name: "Dest",
            id: 'dest'
        },
        {
            name: "Action",
            id: 'Action',
            selector: (row) => {
                return (
                    <>
                        <Button
                            icon={<FiCopy size={18} color="black" />}
                            style={{ paddingInline: 8 }}
                            shape={'round'}
                            type="link"
                            onClick={() => history.push({ pathname: '/tps/import/awb_hawbform', state: { ...row, isCreate: false } })}
                        />
                        <Button
                            icon={<FiEdit size={18} color="black" />}
                            style={{ paddingInline: 8 }}
                            shape={'round'}
                            type="link"
                            onClick={() => history.push({ pathname: '/tps/import/awb_editform', state: { ...row, isCreate: false } })}
                        />
                    </>
                )
            },
        },
    ]
    return (
        <Fragment>
            <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="Airwaybill" key="1">
                    <TablePage
                        title={"Import Airwaybill"}
                        url="api/v1/import_awb"
                        actionPosition={'first'}
                        columns={columns}
                        createPath="/tps/import/awb_form"
                        ExpandComponent={DetailPage}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Report" key="2">
                    <Report />
                </Tabs.TabPane>
            </Tabs>
        </Fragment>
    )
}