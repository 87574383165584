import React from 'react';
import { Button } from 'antd'
import { RiArrowRightCircleLine } from "react-icons/ri";
import { useHistory } from 'react-router-dom'
import moment from 'moment';

export default ({ data }) => {
    const { awbs } = data;

    const history = useHistory();

    return (
        <div style={{ display: 'flex', flexDirection: 'column', padding: 20 }}>
            <table style={{ border: '1px solid black' }}>
                <tr style={{ border: '1px solid black' }}>
                    <th style={{ border: '1px solid black', fontSize: 11 }}>Tanggal</th>
                    <th style={{ border: '1px solid black', fontSize: 11 }}>Awb Number</th>
                    <th style={{ border: '1px solid black', fontSize: 11 }}>Hawb Number</th>
                    <th style={{ border: '1px solid black', fontSize: 11 }}>Pieces</th>
                    <th style={{ border: '1px solid black', fontSize: 11 }}>Weight</th>
                    <th style={{ border: '1px solid black', fontSize: 11 }}>Route</th>
                    <th style={{ border: '1px solid black', fontSize: 11 }}>NO BC11</th>
                    <th style={{ border: '1px solid black', fontSize: 11 }}>TGL BC11</th>
                    <th style={{ border: '1px solid black', fontSize: 11 }}>Action</th>
                </tr>
                {awbs && Array.isArray(awbs) && awbs.map((item, idx) => (
                    <>
                        <tr style={{ border: '1px solid black' }}>
                            <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{moment(item?.createdAt).format('DD-MMM-YYYY')}</td>
                            <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{item?.mawb_number}</td>
                            <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{item?.hawb_number}</td>
                            <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{item?.jml_kemas}</td>
                            <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{item?.bruto}</td>
                            <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{item?.origin?.code} - {item?.dest?.code}</td>
                            <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{item?.no_bc11}</td>
                            <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{item?.tgl_bc11}</td>
                            <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>
                                <RiArrowRightCircleLine size={15} color="black"
                                    style={{ cursor: 'pointer', marginTop: 5 }}
                                    onClick={() => history.push({ pathname: '/tps/import/import_out/form', state: { ...item, isCreate: false } })}
                                />
                            </td>
                        </tr>
                    </>
                ))}
            </table>
        </div>
    )
}