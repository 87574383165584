import React from "react";
import {
  RiHome2Line,
  RiDatabase2Line,
  RiFlightTakeoffLine,
  RiBuildingLine,
  RiWaterFlashLine,
  RiGitRepositoryCommitsLine,
  RiFolderChart2Line,
  RiFolderHistoryLine,
  RiFolderReceivedLine,
  RiContactsBook2Line,
  RiFileExcel2Line,
  RiChatCheckFill,
  RiFlightTakeoffFill,
  RiFlightLandFill,
  RiMapPin4Fill,
  RiGovernmentLine,
  RiSafe2Line,
  RiSafeLine,
  RiFileList3Line,
  RiDirectionLine,
  RiServiceLine,
  RiTerminalFill,
  RiEmpathizeLine,
  RiDiscussLine,
  RiPriceTag2Line,
  RiMailStarLine,
  RiFileTransferLine,
  RiArrowGoForwardFill,
  RiMailFill,
  RiInboxFill,
  RiMailSendLine,
  RiFileLockLine,
  RiCloseCircleLine,
  RiLockLine,
  RiLockUnlockLine,
  RiInboxUnarchiveLine,
  RiInboxArchiveLine
} from "react-icons/ri";
import { GiHarborDock } from "react-icons/gi";
import { FaFileSignature, FaInfoCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
const SIZE = 20;

export default [
  {
    key: "/home",
    icon: <RiHome2Line size={SIZE} />,
    label: <Link to={"/home"}>Home</Link>,
    level: 0x1fff,
  },
  {
    key: "/master",
    icon: <RiDatabase2Line size={SIZE} />,
    label: "Master",
    level: 0x1ff0,
    children: [
      {
        key: "/master/config",
        icon: <RiWaterFlashLine size={SIZE} />,
        label: <Link to={"/master/config"}>Config</Link>,
        level: 0x1ff0,
      },
      {
        key: "/master/airline",
        icon: <RiFlightTakeoffLine size={SIZE} />,
        label: <Link to={"/master/airline"}>Airlines</Link>,
        level: 0x1ff0,
      },
      {
        key: "/master/airport",
        icon: <RiBuildingLine size={SIZE} />,
        level: 0x1ff0,
        label: <Link to={"/master/airport"}>Airport</Link>,
      },
      {
        key: "/master/users",
        icon: <RiContactsBook2Line size={SIZE} />,
        level: 0x1ff0,
        label: <Link to={"/master/users"}>Users</Link>,
      },
    ],
  },
  {
    key: "/fr",
    icon: <RiMapPin4Fill size={SIZE} />,
    label: "Flight Record",
    level: 0x1ff0,
    children: [
      {
        key: "/fr/arrival",
        icon: <RiFlightLandFill size={SIZE} />,
        level: 0x1fff,
        label: <Link to={"/fr/arrival"}>Arrival</Link>,
      },
      {
        key: "/fr/departure",
        icon: <RiFlightTakeoffFill size={SIZE} />,
        level: 0x1fff,
        label: <Link to={"/fr/departure"}>Departure</Link>,
      },
    ],
  },
  // {
  //   key: "/airwaybill",
  //   icon: <FaFileSignature size={SIZE} />,
  //   level: 0x1ff1,
  //   label: "Airwaybill",
  //   children: [
  //     {
  //       key: "/airwaybill/export",
  //       icon: <RiFlightTakeoffFill size={SIZE} />,
  //       level: 0x1ff1,
  //       label: <Link to={"/airwaybill/export"}>Export</Link>,
  //     }
  //   ],
  // },
  {
    key: "/verify",
    icon: <RiChatCheckFill size={SIZE} />,
    level: 0x1ff3,
    label: "Verify Document",
    children: [
      {
        key: "/verify/export",
        icon: <RiFlightTakeoffFill size={SIZE} />,
        level: 0x1ff1,
        label: <Link to={"/verify/export"}>Export</Link>,
      },
      {
        key: "/verify/import",
        icon: <RiFlightLandFill size={SIZE} />,
        level: 0x1ff2,
        label: <Link to={"/verify/import"}>Import</Link>,
      },
    ],
  },
  {
    key: "/tps",
    icon: <RiGovernmentLine size={SIZE} />,
    level: 0x1ff17,
    label: "TPS Online",
    children: [
      {
        key: "/tps/export",
        icon: <RiFlightTakeoffFill size={SIZE} />,
        level: 0x1ff5,
        label: "Export",
        children: [
          {
            key: "/tps/export/awb",
            icon: <RiInboxUnarchiveLine size={SIZE} />,
            level: 0x1ff1,
            label: <Link to={"/tps/export/awb"}>AWB Export</Link>,
          },
          {
            key: "/tps/export/export_in",
            icon: <RiSafeLine size={SIZE} />,
            level: 0x1ff1,
            label: <Link to={"/tps/export/export_in"}>Export Gate In</Link>,
          },
          {
            key: "/tps/export/export_out",
            icon: <RiSafe2Line size={SIZE} />,
            level: 0x1ff1,
            label: <Link to={"/tps/export/export_out"}>Export Gate Out</Link>,
          },
          {
            key: "/tps/export/export_list",
            icon: <RiFileList3Line size={SIZE} />,
            level: 0x1ff5,
            label: <Link to={"/tps/export/export_list"}>Export List</Link>,
          },
          {
            key: "/tps/export/export_report",
            icon: <RiPriceTag2Line size={SIZE} />,
            level: 0x1ff1,
            label: <Link to={"/tps/export/export_report"}>Export Report</Link>,
          },
        ],
      },
      {
        key: "/tps/import",
        icon: <RiFlightLandFill size={SIZE} />,
        level: 0x1ff6,
        label: "Import",
        children: [
          {
            key: "/tps/import/awb",
            icon: <RiInboxArchiveLine size={SIZE} />,
            level: 0x1ff2,
            label: <Link to={"/tps/import/awb"}>AWB Import</Link>,
          },
          {
            key: "/tps/import/import_in",
            icon: <RiSafeLine size={SIZE} />,
            level: 0x1ff2,
            label: <Link to={"/tps/import/import_in"}>Import Gate In</Link>,
          },
          {
            key: "/tps/import/import_out",
            icon: <RiSafe2Line size={SIZE} />,
            level: 0x1ff2,
            label: <Link to={"/tps/import/import_out"}>Import Gate Out</Link>,
          },
          {
            key: "/tps/import/import_list",
            icon: <RiFileList3Line size={SIZE} />,
            level: 0x1ff6,
            label: <Link to={"/tps/import/import_list"}>Import List</Link>,
          },
          {
            key: "/tps/import/import_report",
            icon: <RiPriceTag2Line size={SIZE} />,
            level: 0x1ff2,
            label: <Link to={"/tps/import/import_report"}>Import Report</Link>,
          },
        ],
      },

      {
        key: "/tps/distribusi",
        icon: <RiArrowGoForwardFill size={SIZE} />,
        level: 0x1ff6,
        label: "Distribusi",
        children: [
          {
            key: "/airwaybill/distribusi",
            icon: <RiArrowGoForwardFill size={SIZE} />,
            level: 0x1ff2,
            label: <Link to={"/airwaybill/distribusi"}>AWB Distribusi</Link>,
          },
          {
            key: "/tps/distribusi/distribusi_in",
            icon: <RiSafeLine size={SIZE} />,
            level: 0x1ff2,
            label: (
              <Link to={"/tps/distribusi/distribusi_in"}>Distribusi Gate In</Link>
            ),
          },
          {
            key: "/tps/distribusi/distribusi_out",
            icon: <RiSafe2Line size={SIZE} />,
            level: 0x1ff2,
            label: (
              <Link to={"/tps/distribusi/distribusi_out"}>Distribusi Gate Out</Link>
            ),
          },
          {
            key: "/tps/distribusi/distribusi_list",
            icon: <RiFileList3Line size={SIZE} />,
            level: 0x1ff6,
            label: (
              <Link to={"/tps/distribusi/distribusi_list"}>
                Distribusi List
              </Link>
            ),
          },
          {
            key: "/tps/distribusi/distribusi_report",
            icon: <RiPriceTag2Line size={SIZE} />,
            level: 0x1ff2,
            label: (
              <Link to={"/tps/distribusi/distribusi_report"}>
                Distribusi Report
              </Link>
            ),
          },
        ],
      },

      {
        key: "/tps/plp",
        icon: <RiDirectionLine size={SIZE} />,
        level: 0x1ff11,
        label: "PLP",
        children: [
          {
            key: "/tps/plp/permohonan",
            icon: <RiServiceLine size={SIZE} />,
            level: 0x1ff11,
            label: <Link to={"/tps/plp/permohonan"}>Permohonan PLP</Link>,
          },
        ],
      },
    ],
  },
  {
    key: "/beacukai",
    icon: <GiHarborDock size={SIZE} />,
    level: 0x1ff4,
    label: "Beacukai",
    children: [
      {
        key: "/beacukai/inventory",
        icon: <RiGitRepositoryCommitsLine size={SIZE} />,
        level: 0x1ff4,
        label: "Inventory",
        children: [
          {
            key: "/beacukai/inventory/export",
            icon: <RiFlightTakeoffFill size={SIZE} />,
            level: 0x1ff4,
            label: "Export",
            children: [
              {
                key: "/beacukai/inventory/export/inventory",
                icon: <RiFolderChart2Line size={SIZE} />,
                level: 0x1ff4,
                label: (
                  <Link to={"/beacukai/inventory/export/inventory"}>
                    Inventory
                  </Link>
                ),
              },
              {
                key: "/beacukai/inventory/export/abandon",
                icon: <RiFolderHistoryLine size={SIZE} />,
                level: 0x1ff4,
                label: (
                  <Link to={"/beacukai/inventory/export/abandon"}>Abandon</Link>
                ),
              },
              {
                key: "/beacukai/inventory/export/current_now",
                icon: <RiFolderHistoryLine size={SIZE} />,
                level: 0x1ff4,
                label: (
                  <Link to={"/beacukai/inventory/export/current_now"}>
                    Current Now
                  </Link>
                ),
              },
              {
                key: "/beacukai/inventory/export/custome_module",
                icon: <RiFolderReceivedLine size={SIZE} />,
                level: 0x1ff4,
                label: (
                  <Link to={"/beacukai/inventory/export/custome_module"}>
                    Customs Module
                  </Link>
                ),
              },
            ],
          },
          {
            key: "/beacukai/inventory/import/inventory",
            icon: <RiFlightLandFill size={SIZE} />,
            level: 0x1ff4,
            label: "Import",
            children: [
              {
                key: "/beacukai/inventory/import/inventory",
                icon: <RiFolderChart2Line size={SIZE} />,
                level: 0x1ff4,
                label: (
                  <Link to={"/beacukai/inventory/import/inventory"}>
                    Inventory
                  </Link>
                ),
              },
              {
                key: "/beacukai/inventory/import/abandon",
                icon: <RiFolderHistoryLine size={SIZE} />,
                level: 0x1ff4,
                label: (
                  <Link to={"/beacukai/inventory/import/abandon"}>Abandon</Link>
                ),
              },
              {
                key: "/beacukai/inventory/import/current_now",
                icon: <RiFolderHistoryLine size={SIZE} />,
                level: 0x1ff4,
                label: (
                  <Link to={"/beacukai/inventory/import/current_now"}>
                    Current Now
                  </Link>
                ),
              },
              {
                key: "/beacukai/inventory/import/custome_module",
                icon: <RiFolderReceivedLine size={SIZE} />,
                level: 0x1ff4,
                label: (
                  <Link to={"/beacukai/inventory/import/custome_module"}>
                    Customs Module
                  </Link>
                ),
              },
            ],
          },
          {
            key: "/beacukai/inventory/distribusi/inventory",
            icon: <RiArrowGoForwardFill size={SIZE} />,
            level: 0x1ff4,
            label: "Distribusi",
            children: [
              {
                key: "/beacukai/inventory/distribusi/inventory",
                icon: <RiFolderChart2Line size={SIZE} />,
                level: 0x1ff4,
                label: (
                  <Link to={"/beacukai/inventory/distribusi/inventory"}>
                    Inventory
                  </Link>
                ),
              },
              {
                key: "/beacukai/inventory/distribusi/abandon",
                icon: <RiFolderHistoryLine size={SIZE} />,
                level: 0x1ff4,
                label: (
                  <Link to={"/beacukai/inventory/distribusi/abandon"}>
                    Abandon
                  </Link>
                ),
              },
              {
                key: "/beacukai/inventory/distribusi/current_now",
                icon: <RiFolderHistoryLine size={SIZE} />,
                level: 0x1ff4,
                label: (
                  <Link to={"/beacukai/inventory/distribusi/current_now"}>
                    Current Now
                  </Link>
                ),
              },
              {
                key: "/beacukai/inventory/distribusi/custome_module",
                icon: <RiFolderReceivedLine size={SIZE} />,
                level: 0x1ff4,
                label: (
                  <Link to={"/beacukai/inventory/distribusi/custome_module"}>
                    Customs Module
                  </Link>
                ),
              },
            ],
          },
        ],
      },
      {
        key: "/beacukai/hold_release",
        icon: <RiFileLockLine size={SIZE} />,
        level: 0x1ff4,
        label: "Hold & Release",
        children: [
          {
            key: "/beacukai/hold_release/hold",
            icon: <RiLockLine size={SIZE} />,
            level: 0x1ff4,
            label: (
              <Link to={"/beacukai/hold_release/hold"}>
                Hold
              </Link>
            ),
          },
          {
            key: "/beacukai/hold_release/release",
            icon: <RiLockUnlockLine size={SIZE} />,
            level: 0x1ff4,
            label: (
              <Link to={"/beacukai/hold_release/release"}>
                Release
              </Link>
            ),
          },
        ]
      }
    ],
  },
  {
    key: "/telex",
    icon: <RiMailFill size={SIZE} />,
    level: 0x1ffb,
    label: "Airwaybill & Telex",
    children: [
      {
        key: "/airwaybill/import/import_in",
        icon: <RiSafeLine size={SIZE} />,
        level: 0x1ff8,
        label: <Link to={"/airwaybill/import/import_in"}>Import Gate In</Link>,
      },
      {
        key: "/telex/airwaybill",
        icon: <RiInboxFill size={SIZE} />,
        level: 0x1ff8,
        label: <Link to={"/telex/airwaybill"}>Awb Telex</Link>,
      },
      {
        key: "/telex/tps",
        icon: <RiMailSendLine size={SIZE} />,
        level: 0x1ffb,
        label: "Manifest",
        children: [
          {
            key: "/telex/international",
            icon: <RiFileTransferLine size={SIZE} />,
            level: 0x1ffb,
            label: <Link to={"/telex/international"}>International</Link>,
          },
          {
            key: "/telex/domestic",
            icon: <RiFileTransferLine size={SIZE} />,
            level: 0x1ffb,
            label: <Link to={"/telex/domestic"}>Domestic</Link>,
          },
        ],
      },
      {
        key: "/telex/rksp",
        icon: <RiFileExcel2Line size={SIZE} />,
        level: 0x1ff8,
        label: <Link to={"/telex/rksp"}>RKSP</Link>,
      },
      {
        key: "/logs/telex",
        icon: <RiMailStarLine size={SIZE} />,
        level: 0x1ff8,
        label: <Link to={"/logs/telex"}>Telex Message</Link>,
      },
    ],
  },
  {
    key: "/logs",
    icon: <RiTerminalFill size={SIZE} />,
    level: 0x1ff4,
    label: "Logs",
    children: [
      {
        key: "/logs/activity",
        icon: <RiEmpathizeLine size={SIZE} />,
        level: 0x1ff2,
        label: <Link to={"/logs/activity"}>Activity</Link>,
      },
      {
        key: "/logs/message",
        icon: <RiDiscussLine size={SIZE} />,
        level: 0x1ff4,
        label: <Link to={"/logs/message"}>Message</Link>,
      },
    ],
  },
  {
    key: "/about",
    icon: <FaInfoCircle size={SIZE} />,
    level: 0x1ff4,
    label: <Link to={"/about"}>About</Link>,
  },
];
