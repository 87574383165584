import React, { useState } from 'react'
import { Modal, Form, Input, Select, Button, Row, Col, DatePicker, message } from 'antd';
import { PostAPI } from '../../../../../redux';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import TablePage from '../../../../../components/pages/TablePage';
import {
    RiArrowLeftCircleFill
} from 'react-icons/ri';
const { Option } = Select;

export default ({ isModalOpen, handleCancel, awb, setRefresh }) => { // eslint-disable-line

    const dispatch = useDispatch()
    const [dataDocument, setDataDocument] = useState(null)
    const [refresh, setRefreshTable] = useState(moment().unix());
    const [docType, setDocType] = useState(null) // eslint-disable-line
    const [idDoc, setIdDoc] = useState(null)
    const [form] = Form.useForm();

    const closeModal = () => {
        setDataDocument(null)
        setDocType(null)
        setIdDoc(null)
        handleCancel()
        form.resetFields();
    }

    const findDocument = (value) => {
        dispatch(PostAPI({ url: 'api/v1/verify/import_verify', data: { ...value, doc_date: moment(value?.doc_date).format("DDMMYYYY") } }))
            .then(resp => {
                if (resp?.payload?.gudang) {
                    setDataDocument({ ...resp.payload, doc_type: value?.current_kd_dok })
                } else {
                    setDataDocument(null)
                    message.error('Tidak Menemukan Data');
                }
            })
    }

    const saveDocument = () => {
        const { no_sppb, tgl_sppb,
            npwp_imp, doc_no } = dataDocument;
        dispatch(PostAPI({
            url: 'api/v1/verify/import_verify_save', data: {
                _id: awb?._id, id_doc: idDoc, doc_type: docType,
                no_sppb, tgl_sppb,
                npwp_imp, doc_no
            }
        }))
            .then(resp => {
                if (resp.payload) {
                    setRefresh(moment().unix())
                    handleCancel()
                    closeModal()
                    message.success('berhasil verifikasi');
                }
            })
    }

    const saveVerify = () => {
        form.validateFields()
            .then((values) => {
                const { current_kd_dok, doc_date, no_dok_inout, exportir_npwp, doc_no } = values;
                dispatch(PostAPI({
                    url: 'api/v1/verify/doc_save', data: {
                        doc_type: current_kd_dok || '',
                        doc_date: doc_date || '',
                        doc_code: doc_no || '',
                        no_doc: no_dok_inout || '',
                        no_npwp: exportir_npwp || '',
                        exp_imp: 'import',
                    }
                }))
                    .then(resp => {
                        if (resp.payload) {
                            setRefreshTable(moment().unix());
                            form.resetFields();
                            message.success('berhasil save');
                        }
                    })
            })
    }

    const handleChange = (value) => {
        setDocType(value)
    };

    const columns = [
        {
            name: "Action",
            id: '_id',
            width: '100px',
            selector: (row) => {
                const { _id, doc_type, no_doc, no_npwp, doc_date, doc_code } = row;
                return (
                    <RiArrowLeftCircleFill size={20} style={{ color: 'green', cursor: 'pointer' }}
                        onClick={() => {
                            if (form) {
                                form.setFieldsValue({
                                    current_kd_dok: doc_type,
                                    no_dok_inout: no_doc,
                                    doc_no: doc_code,
                                    exportir_npwp: no_npwp,
                                    doc_date: moment(doc_date, 'YYYY-MM-DD')
                                });
                                setIdDoc(_id)
                                handleChange(doc_type)
                            }
                        }}
                    />
                )
            }
        },
        {
            selector: (row) => row?.no_doc,
            name: "No Doc",
            id: 'no_doc',
            width: '200px'
        },
        {
            selector: (row) => row?.doc_type,
            name: "Doc Type",
            id: 'doc_type'
        }
    ]

    return (
        <div>
            <Modal title={`Verify AWB ${awb?.mawb_number}`} visible={isModalOpen} onCancel={closeModal} footer={null} width={1200}>
                <Row justify="space-around">
                    <Col span={9}>
                        <Form
                            layout='vertical'
                            onFinish={findDocument}
                            form={form}
                        >
                            <Form.Item
                                name="current_kd_dok"
                                label="Type Document"
                                rules={[
                                    {
                                        required: true,
                                        message: "Type Document wajib di isi"
                                    },
                                ]}
                            >
                                <Select
                                    allowClear
                                    onChange={handleChange}
                                >
                                    <Option value="SPPB2.0">SPPB BC 2.0</Option>
                                    <Option value="SPPB2.3">SPPB BC 2.3</Option>
                                    <Option value="PLP">PLP</Option>
                                    <Option value="dokumen_pabean_permit">Dokumen Pabean Permit</Option>
                                </Select>
                            </Form.Item>

                            {(docType === "dokumen_pabean_permit") &&
                                <Form.Item
                                    name="doc_no"
                                    label="Kode Document"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Kode Document wajib di isi"
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        allowClear
                                    >
                                        <Option value="41">41, BC 1.6</Option>
                                        <Option value="60">60, SPPB Rush Handling</Option>
                                        <Option value="61">61, KEK- Pemasukan dari LDP</Option>
                                        <Option value="62">62, KEK- Pemasukan melalui Barang Kiriman</Option>
                                        <Option value="63">63, KEK- Pemasukan dari TLDDP</Option>
                                        <Option value="64">64, KEK- Pengeluaran ke KEK/TPB/FTZ</Option>
                                        <Option value="65">65, KEK- Pengeluaran ke LDP</Option>
                                        <Option value="66">66, KEK- Pengeluaran ke TLDDP</Option>
                                        <Option value="42">42, Persetujuan Keluar Barang Kiriman</Option>
                                        <Option value="43">43, SPPBMC</Option>
                                        <Option value="44">44, Impor dengan BC 1.1 Outward Manifes</Option>
                                        <Option value="45">45, NPP3BET(Nota Persetujuan Pengeluaran P3BET)</Option>
                                        <Option value="47">47, PPFTZ-01 Asal LDP (Impor)</Option>
                                        <Option value="48">48, PPFTZ-01 Tujuan TLDDP</Option>
                                        <Option value="49">49, PPFTZ-01 Tujuan LDP (Ekspor)</Option>
                                        <Option value="50">50, PPFTZ-02 Masuk</Option>
                                        <Option value="51">51, PPFTZ-02 Keluar</Option>
                                        <Option value="52">52, PPFTZ-03</Option>
                                        <Option value="56">56, Ekspor dengan BC 1.1 Inward Manifes</Option>
                                        <Option value="59">59, Dokumen BC 1.4</Option>
                                    </Select>
                                </Form.Item>
                            }

                            <Form.Item
                                name="no_dok_inout"
                                label="No Document"
                                rules={[
                                    {
                                        required: true,
                                        message: "No Document wajib di isi"
                                    },
                                ]}
                            >
                                <Input autoComplete="off" onInput={e => e.target.value = e.target.value.toUpperCase()} />
                            </Form.Item>
                            <Form.Item
                                name="doc_date"
                                label="Tanggal Doc"
                                rules={[
                                    {
                                        required: true,
                                        message: "Tanggal Doc wajib di isi"
                                    },
                                ]}
                            >
                                <DatePicker style={{ width: '100%' }} placeholder="" />
                            </Form.Item>

                            {(docType === "SPPB2.0" || docType === "SPPB2.3") &&
                                <Form.Item
                                    name="exportir_npwp"
                                    label="NPWP"
                                    rules={[
                                        {
                                            required: true,
                                            message: "NPWP wajib di isi"
                                        },
                                    ]}
                                >
                                    <Input autoComplete="off" />
                                </Form.Item>
                            }
                            <Form.Item style={{ marginTop: 60 }}>
                                <Button type="primary" htmlType="submit">
                                    Check Verify Document
                                </Button>
                                <Button type="dashed" style={{ marginLeft: 10 }} onClick={() => {
                                    saveVerify()
                                }}>
                                    Save
                                </Button>
                                <Button type="dashed" style={{ marginLeft: 10 }} onClick={() => {
                                    setDataDocument(null)
                                    form.resetFields()
                                }}>
                                    Clear
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                    {dataDocument === null ?
                        <Col span={14}>
                            <TablePage
                                url="api/v1/verify/verify_doc/import"
                                columns={columns}
                                refresh={refresh}
                                title={"Import Verify"}
                            />
                        </Col> :
                        <Col span={14} >
                            <table style={{ marginLeft: 30 }}>
                                <tr>
                                    <td style={{ width: 100 }}>Nama Gudang</td>
                                    <td>: {dataDocument?.gudang}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 100 }}>No SPPB</td>
                                    <td>: {dataDocument?.no_sppb}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 100 }}>TGL SPPB</td>
                                    <td>: {dataDocument?.tgl_sppb}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 100 }}>Nama Angkut</td>
                                    <td>: {dataDocument?.nama_angkut}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 100 }}>No Flight</td>
                                    <td>: {dataDocument?.no_voy_flight}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 100 }}>AWB No</td>
                                    <td>: {dataDocument?.awb_number}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 100 }}>AWB Date</td>
                                    <td>: {dataDocument?.awb_date}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 100 }}>HAWB No</td>
                                    <td>: {dataDocument?.hawb_number}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 100 }}>HAWB Date</td>
                                    <td>: {dataDocument?.hawb_date}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 100 }}>Name IMP</td>
                                    <td>: {dataDocument?.nama_imp}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 100 }}>Alamat IMP</td>
                                    <td>: {dataDocument?.alamat_imp}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 100 }}>NPWP IMP</td>
                                    <td>: {dataDocument?.npwp_imp}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 100 }}>Name PPJK</td>
                                    <td>: {dataDocument?.nama_ppjk}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 100 }}>No PIB</td>
                                    <td>: {dataDocument?.no_pib}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 100 }}>Tgl PIB</td>
                                    <td>: {dataDocument?.tgl_pib}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 100 }}>Weight</td>
                                    <td>: {dataDocument?.bruto}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 100 }}>Netto</td>
                                    <td>: {dataDocument?.netto}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 100 }}>Pieces</td>
                                    <td>: {dataDocument?.jml_kms}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 100 }}>Pieces</td>
                                    <td>: {dataDocument?.jns_kms}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 100 }}>No BC11</td>
                                    <td>: {dataDocument?.no_bc11}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 100 }}>No Pos BC11</td>
                                    <td>: {dataDocument?.no_pos_bc11}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 100 }}>TGL BC11</td>
                                    <td>: {dataDocument?.tgl_bc11}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 100 }}>DOC NO</td>
                                    <td>: {dataDocument?.doc_no}</td>
                                </tr>
                            </table>
                            <Button type="primary" style={{ marginLeft: 32, width: '90%', marginTop: 10 }} onClick={() => saveDocument()}>
                                Save
                            </Button>
                        </Col>
                    }
                </Row>
            </Modal>
        </div>
    )
}