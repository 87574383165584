import React, { Fragment, useState } from 'react';
import moment from 'moment';
import { RiFlightTakeoffFill } from "react-icons/ri";
import { Form, Input, Row, Col, DatePicker, InputNumber, Tooltip } from 'antd';
import FormPage from '../../../../../../components/pages/FormPage';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const DefModel = {
    flight_date: moment(),
    mawb_date: moment(),
    hawb_date: moment(),
    jml_kemas: 0,
    bruto: 0
}

export default () => {
    const history = useHistory();
    const [model, setModel] = useState(DefModel);
    const resultModel = { ...model, flight_date: moment(model?.flight_date), mawb_date: moment(model?.mawb_date), hawb_date: moment(model?.hawb_date) }
    const formatAwbNumber = (value) => {
        let str = value;
        if (str.length > 4 && str.substring(3, 4) !== '-') {
            str = str.substring(0, 3) + '-' + str.substring(3);
        }
        return str;
    }
    return (
        <Fragment>
            <FormPage
                url={'api/v1/import_awb/edit'}
                formValid={false}
                model={!!model?.flight_number ? resultModel : model}
                layout="vertical"
                setModel={setModel}
                formSize="default"
                title={"Master Awb"}
                emptyModel={DefModel}
                callbackPath={"/tps/import/awb"}
                onSaveEvent={(model) => {
                    const { _id, flight_date, mawb_date, hawb_date, flight_number, hawb_number, mawb_number, jml_kemas, bruto } = model;
                    return {
                        _id,
                        flight_number,
                        flight_date: moment(flight_date).format('DD-MMM-YYYY'),
                        mawb_number,
                        mawb_date: moment(mawb_date).format('YYYYMMDD'),
                        hawb_number,
                        hawb_date: moment(hawb_date).format('YYYYMMDD'),
                        jml_kemas,
                        bruto
                    }
                }}
            >
                <Row gutter={24} style={{ width: '50%' }}>
                    <Col span={12}>
                        <Form.Item
                            label="Flight Number"
                            name="flight_number"
                            rules={[{ required: true, message: 'Please enter the flight number' }]}
                        >
                            <Input
                                autoFocus
                                autoComplete="off"
                                onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
                                addonAfter={<Tooltip placement="topLeft" title="Create Flight">
                                    <RiFlightTakeoffFill style={{ cursor: "pointer" }} onClick={() => history.push({ pathname: '/fr/create' })} />
                                </Tooltip>}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label="flight date" name={'flight_date'} rules={[{ required: true, message: "Flight Date wajib di isi" }]}>
                            <DatePicker style={{ width: '100%' }} format="DD-MMM-YYYY" placeholder='' />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label="mawb number" name={'mawb_number'}
                            rules={[
                                {
                                    required: true,
                                    pattern: /^\d{3}-\d+$/,
                                    message: 'Nomor mawb harus dalam format 000-0000000',
                                },
                            ]}
                            getValueFromEvent={(event) => formatAwbNumber(event.target.value)}
                        >
                            <Input autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="mawb date" name={'mawb_date'} rules={[{ required: true, message: "MAWB Date wajib di isi" }]}>
                            <DatePicker style={{ width: '100%' }} format="DD-MMM-YYYY" placeholder='' />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label="hawb number" name={'hawb_number'}
                        >
                            <Input autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="hawb date" name={'hawb_date'} rules={[{ required: true, message: "HAWB Number wajib di isi" }]}>
                            <DatePicker style={{ width: '100%' }} format="DD-MMM-YYYY" placeholder='' />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label="pieces" name={'jml_kemas'} rules={[{ required: true, message: "Pieces wajib di isi" }]}>
                            <InputNumber style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="weight" name={'bruto'} rules={[{ required: true, message: "Weight wajib di isi" }]}>
                            <InputNumber style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>
            </FormPage>
        </Fragment >
    )
}