import React, { useMemo, useState } from 'react'
import { Layout, Menu, Typography } from 'antd';
import './layout.css';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedMenu, setOpenKeys } from '../../redux';
import { RiMenuFoldLine, RiMenuUnfoldLine } from 'react-icons/ri'
import moment from 'moment';
import { UserProfile } from './userProfile';
import { Scrollbars } from 'react-custom-scrollbars';
import CFG from '../../config/env';
const { Header, Content, Footer, Sider } = Layout;
const { Title } = CFG;

export const SiderLayout = ({ children, menus, title, theme, headerBgColor, headerColor }) => {
    const { selectedMenu, openKeys } = useSelector(state => state.nav);
    const { userdata } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const [colapsed, setColapsed] = useState(false);
    const rootSubmenuKeys = useMemo(() => {
        return menus.map(({ key }) => key);
    }, [menus]);
    const renderThumb = ({ style }) => {
        return (<div style={{ ...style, backgroundColor: '#888', width: 20 }} />)
    }
    return (
        <Layout hasSider>
            <Sider
                style={{ height: '100vh', position: 'fixed', top: 0, bottom: 0, left: 0 }}
                breakpoint="lg"
                theme={theme || 'dark'}
                collapsed={colapsed}
                width={260}
            >
                {colapsed === false ?
                    <div className='logo'>
                        <img src={'/assets/images/login-dashboard.jpg'} alt="Logo PT" width="45%" />
                    </div> :
                    <div className='logo-md'>
                    </div>
                }
                <Scrollbars
                    renderThumbVertical={renderThumb}
                >
                    <Menu
                        mode="inline"
                        selectedKeys={[selectedMenu]}
                        theme={theme || 'dark'}
                        items={menus || []}
                        onClick={e => {
                            dispatch(setSelectedMenu(e.key));
                        }}
                        openKeys={openKeys}
                        onOpenChange={keys => {
                            const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
                            if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
                                dispatch(setOpenKeys(keys));
                            } else {
                                dispatch(setOpenKeys(latestOpenKey ? [latestOpenKey] : []));
                            }
                        }}
                    />
                </Scrollbars>
            </Sider>
            <Layout style={{ marginLeft: colapsed ? 80 : 260, minHeight: '100vh' }}>
                <Header className="site-layout-sub-header-background" style={{ backgroundColor: headerBgColor, width: '100%', zIndex: 99, position: 'fixed', color: headerColor, display: 'flex', flexDirection: 'row' }}>
                    {
                        colapsed ? <RiMenuFoldLine className='trigger' onClick={() => setColapsed(!colapsed)} /> : <RiMenuUnfoldLine className='trigger' onClick={() => setColapsed(!colapsed)} />
                    }
                    <Typography.Title level={4} style={{ color: headerColor }}>
                        {title || Title}
                    </Typography.Title>
                    <UserProfile isColapsed={colapsed} username={userdata?.username} level={userdata?.levelStr} />
                </Header>
                <Content style={{ margin: '80px 16px 0', display: 'flex', flexDirection: 'column', minHeight: `calc(90% - 80px)` }}>
                    <div className="site-layout-background" style={{ padding: 24, flexGrow: 1 }}>
                        {children}
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center', height: 64 }}>
                    COPYRIGHT © {moment().format("YYYY")} <a target={'_blank'} href="https://edifly-si.com/">Edifly Solusi Indonesia</a>, All rights Reserved
                </Footer>
            </Layout>
        </Layout>
    )
}