import { Form, Input, Row, Col, DatePicker, InputNumber, Button } from 'antd';
import React, { useMemo, useState } from 'react'
import FormPage from '../../../../../components/pages/FormPage'
import moment from 'moment'
import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom'
import { useFetch } from '../../../../../hooks/useFetch';
import { PostAPI } from '../../../../../redux';
import { useDispatch } from 'react-redux'

const DefModel = {
    flight_number: null,
    flight_date: moment(),
    mawb_number: '',
    hawb_number: '',
    mawb_date: moment(),
    hawb_date: moment(),
    dest: '',
    bruto: 0,
    jml_kemas: 0
}

export default () => { // eslint-disable-line

    const [localRefresh, setLocalRefresh] = useState(moment().unix())
    const [model, setModel] = useState({ ...DefModel });
    const history = useHistory();
    const dispatch = useDispatch();

    const resultModel = { ...model, bruto: 0, jml_kemas: 0, flight_date: moment(model?.flight_date), mawb_date: moment(model?.mawb_date), hawb_date: moment(model?.hawb_date) }
    const formatAwbNumber = (value) => {
        let str = value;
        if (str.length > 4 && str.substring(3, 4) !== '-') {
            str = str.substring(0, 3) + '-' + str.substring(3);
        }
        return str
    }

    const uri = useMemo(() => {
        const r = localRefresh;
        return `api/v1/tps_distribusi/airwaybill/${!model?.import_awb_id ? model?._id : model?.import_awb_id}?timestamp=${r}`;
    }, [model, localRefresh])

    const [rows, loading] = useFetch(uri);

    const onHawbList = (time) => {
        setModel({ ...time })
    }

    return (
        <FormPage
            formValid={false}
            model={!!model?.flight_number ? resultModel : model}
            layout="vertical"
            setModel={setModel}
            formSize="default"
            title={"Pecah AWB"}
            emptyModel={DefModel}
            callbackPath={"/airwaybill/distribusi"}
            onSaveEvent={(model) => {
                const { mawb_date, hawb_date, flight_date, tgl_bc11, shipper_id, shipper_name, consignee_id, consignee_name } = model
                const url = 'api/v1/tps_distribusi/airwaybill';
                dispatch(PostAPI({
                    url, data: {
                        ...model,
                        shp_npwp_master: shipper_id,
                        shp_name_master: shipper_name,
                        exportir_npwp_master: consignee_id,
                        exportir_name_master: consignee_name,

                        flight_date: moment(flight_date).format('DD-MMM-YYYY'),
                        tgl_bc11: moment(tgl_bc11).format('YYYYMMDD'),
                        mawb_date: moment(mawb_date).format('YYYYMMDD'),
                        hawb_date: moment(hawb_date).format('YYYYMMDD')
                    }
                })).then(resp => {
                    if (!!resp?.payload) {
                        setLocalRefresh(moment().unix())
                    }
                })
            }}
        >
            <Row>
                <Row gutter={24} style={{ width: '50%' }}>
                    <Col span={12}>
                        <Form.Item label="flight number" name={'flight_number'} rules={[{ required: true }]}>
                            <Input autoComplete="off" disabled />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="flight date" name={'flight_date'} rules={[{ required: true }]}>
                            <DatePicker style={{ width: '100%' }} format="DD-MMM-YYYY" placeholder='' disabled />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label="mawb number" name={'mawb_number'}
                            rules={[
                                {
                                    required: true,
                                    pattern: /^\d{3}-\d+$/,
                                    message: 'Nomor mawb harus dalam format 000-0000000',
                                },
                            ]}
                            getValueFromEvent={(event) => formatAwbNumber(event.target.value)}
                        >
                            <Input autoComplete="off" disabled />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="mawb date" name={'mawb_date'} rules={[{ required: true }]}>
                            <DatePicker style={{ width: '100%' }} format="DD-MMM-YYYY" placeholder='' />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label="hawb number" name={'hawb_number'}
                        >
                            <Input autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="hawb date" name={'hawb_date'} rules={[{ required: true }]}>
                            <DatePicker style={{ width: '100%' }} format="DD-MMM-YYYY" placeholder='' />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label={`Pieces (${model?.jml_kemas})`} name={'jml_kemas'} rules={[{ required: true }]}>
                            <InputNumber style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={`Bruto (${model?.bruto})`} name={'bruto'} rules={[{ required: true }]}>
                            <InputNumber style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label="npwp shipper" name={'shp_npwp'}
                        >
                            <Input autoComplete="off" maxLength={16} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="shipper name" name={'shp_name'}
                        >
                            <Input autoComplete="off" onInput={e => e.target.value = e.target.value.toUpperCase()} />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label="npwp consignee" name={'exportir_npwp'}
                        >
                            <Input autoComplete="off" maxLength={16} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="consignee name" name={'exportir_name'}
                        >
                            <Input autoComplete="off" onInput={e => e.target.value = e.target.value.toUpperCase()} />
                        </Form.Item>
                    </Col>

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginBlock: 16 }}>
                        <Form.Item label=" " colon={false} noStyle>
                            <Button size='large' style={{ marginInline: 16 }} icon={<SaveOutlined />} type="primary" htmlType='submit' >
                                Save
                            </Button>
                            <Button size='large' icon={<ArrowLeftOutlined />} type="primary" danger onClick={() => history.goBack()} >
                                Back
                            </Button>
                        </Form.Item>
                    </div>
                </Row>
                <Col span={8} style={{ marginLeft: 50 }}>
                    <table style={{ border: '1px solid black' }}>
                        <tr style={{ border: '1px solid black' }}>
                            <th style={{ border: '1px solid black', fontSize: 11, width: 100 }}>Awb Number</th>
                            <th style={{ border: '1px solid black', fontSize: 11, width: 100 }}>Hawb Number</th>
                            <th style={{ border: '1px solid black', fontSize: 11, width: 100 }}>Pieces</th>
                            <th style={{ border: '1px solid black', fontSize: 11, width: 100 }}>Weight</th>
                        </tr>
                        {rows && Array.isArray(rows) && rows.map((item, idx) => (
                            <>
                                <tr style={{ border: '1px solid black', cursor: "pointer" }} onClick={() => onHawbList(item)}>
                                    <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{item?.mawb_number}</td>
                                    <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{item?.hawb_number}</td>
                                    <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{item?.jml_kemas}</td>
                                    <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{item?.bruto}</td>
                                </tr>
                            </>
                        ))}
                        <tr style={{ border: '1px solid black' }}>
                            <th colSpan={2} style={{ border: '1px solid black', fontSize: 11, width: 100 }}>Total</th>
                            <th style={{ border: '1px solid black', fontSize: 11, width: 100 }}>{Array.isArray(rows) && rows?.reduce((accumulator, currentValue) => accumulator + currentValue.jml_kemas, 0)}</th>
                            <th style={{ border: '1px solid black', fontSize: 11, width: 100 }}>{Array.isArray(rows) && rows?.reduce((accumulator, currentValue) => accumulator + currentValue.bruto, 0)}</th>
                        </tr>
                    </table>
                </Col>
            </Row>
        </FormPage>
    )
}