import { Form, Input, Row, Col, DatePicker, Select } from 'antd';
import React, { useState } from 'react'
import FormPage from '../../../../../../components/pages/FormPage2'
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import kdKemas from '../../../../../../assets/kode_kemas.json';

const { Option } = Select;

const DefModel = {
    cont_asal: ''
}

export default () => {
    const history = useHistory();
    const [model, setModel] = useState({ ...DefModel });

    const values = history?.location?.pathname.split("/");
    const loc = values[1];

    const options = kdKemas.map(item => ({
        ...item,
        name: `${item?.value}, ${item?.name.trim()}`
    }));

    return (
        <FormPage
            url={'api/v1/tps_import/import_in'}
            formValid={false}
            model={{
                ...model,
                is_wk_inout: moment(),
                hawb_date: !model?.hawb_date ? moment() : moment(model?.hawb_date),
                mawb_date: !model?.mawb_date ? moment() : moment(model?.mawb_date),
                tgl_bc11: !model?.tgl_bc11 ? moment() : moment(model?.tgl_bc11)
            }}
            layout="vertical"
            setModel={setModel}
            formSize="default"
            title={"Form Import In"}
            emptyModel={DefModel}
            callbackPath={`/${loc}/import/import_in`}
            onSaveEvent={(model) => {
                const {
                    _id, exportir_npwp, no_bc11, no_pos_bc11, kode_kemas, tgl_tiba, exportir_name,
                    hawb_number, hawb_date, mawb_number, mawb_date,
                    bruto, jml_kemas, tgl_bc11, wk_inout
                } = model;
                return {
                    _id, exportir_npwp, no_bc11, no_pos_bc11,
                    tgl_tiba, exportir_name, hawb_number, mawb_number,
                    bruto, jml_kemas,
                    wk_inout: moment(wk_inout).format('YYYYMMDDHHmmss'),
                    hawb_date: moment(hawb_date).format('YYYYMMDD'),
                    mawb_date: moment(mawb_date).format('YYYYMMDD'),
                    tgl_bc11: moment(tgl_bc11).format('YYYYMMDD'),
                    kode_kemas: kode_kemas.toUpperCase()
                }
            }}
        >
            <Row gutter={16} style={{ width: '80%' }}>
                <Col span={6}>
                    <Form.Item
                        label={"NO HAWB"}
                        name={"hawb_number"}
                        rules={[
                            {
                                required: true,
                                message: "NO HAWB wajib di isi"
                            },
                        ]}
                    >
                        <Input autoComplete="off" defaultValue={model?.hawb_number} />
                    </Form.Item>
                </Col>

                <Col span={6}>
                    <Form.Item
                        label={"TGL HAWB"}
                        name={'hawb_date'}
                        rules={[
                            {
                                required: true,
                                message: "TGL HAWB wajib di isi"
                            },
                        ]}
                    >
                        <DatePicker
                            style={{ width: '100%' }}
                            format="DD-MMM-YYYY"
                            allowClear={false}
                        />
                    </Form.Item>
                </Col>

                <Col span={6}>
                    <Form.Item label={"NO MAWB"}
                        name={'mawb_number'}
                        rules={[
                            {
                                required: true,
                                message: "NO MAWB wajib di isi"
                            },
                        ]}
                    >
                        <Input autoComplete="off" defaultValue={model?.mawb_number} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label={"TGL MAWB"}
                        name={"mawb_date"}
                        rules={[
                            {
                                required: true,
                                message: "TGL MAWB wajib di isi"
                            },
                        ]}
                    >
                        <DatePicker
                            style={{ width: '100%' }}
                            format="DD-MMM-YYYY"
                            allowClear={false}
                        />
                    </Form.Item>
                </Col>


                <Col span={24}>
                    <Form.Item
                        label={"CONSIGNEE"}
                        name={"exportir_name"}
                        rules={[
                            {
                                required: true,
                                message: "CONSIGNEE wajib di isi"
                            },
                        ]}>
                        <Input autoComplete="off" defaultValue={model?.exportir_name} onInput={e => e.target.value = e.target.value.toUpperCase()} />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item
                        label={"KOLI (PCS)"}
                        name={"jml_kemas"}
                        rules={[
                            {
                                required: true,
                                message: "KOLI (PCS) wajib di isi"
                            },
                        ]}
                    >
                        <Input autoComplete="off" defaultValue={model?.jml_kemas} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={"KILO (KG)"}
                        name={"bruto"}
                        rules={[
                            {
                                required: true,
                                message: "KILO (KG) wajib di isi"
                            },
                        ]}
                    >
                        <Input autoComplete="off" defaultValue={model?.bruto} />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item
                        label={"NO BC11"}
                        name={"no_bc11"}
                        rules={[
                            {
                                required: true,
                                message: "NO BC11 wajib di isi"
                            },
                        ]}
                    >
                        <Input maxLength={6} autoComplete="off" defaultValue="" />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label={"TGL BC11"}
                        name={"tgl_bc11"}
                        rules={[
                            {
                                required: true,
                                message: "TGL BC11 wajib di isi"
                            },
                        ]}
                    >
                        <DatePicker
                            style={{ width: '100%' }}
                            format="DD-MMM-YYYY"
                            allowClear={false}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label={"NO POS BC11"}
                        name={"no_pos_bc11"}
                        rules={[
                            {
                                required: true,
                                message: "NO POS BC11 wajib di isi"
                            },
                        ]}
                    >
                        <Input maxLength={12} autoComplete="off" defaultValue="" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={"KODE KEMAS"}
                        name={"kode_kemas"}
                        rules={[
                            {
                                required: true,
                                message: "KODE KEMAS wajib di isi"
                            },
                        ]}>
                        <Select
                            showSearch
                            placeholder=""
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option?.children?.toLowerCase().includes(input.toLowerCase())
                            }
                        >
                            {options.map((option) => (
                                <Option key={option.value} value={option.value} label={option.name}>
                                    {option.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={"WAKTU INOUT"}
                        name={'wk_inout'}
                        rules={[
                            {
                                required: true,
                                message: "WAKTU INOUT wajib di isi"
                            },
                        ]}
                    >
                        <DatePicker
                            showTime
                            style={{ width: '100%' }}
                            value={null}
                            allowClear={false}
                            placeholder=''
                            disabledDate={(current) => {
                                return current && current > moment().endOf('day');
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </FormPage >
    )
}