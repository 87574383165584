import React, { useState } from 'react';
import moment from 'moment';
import numeral from 'numeral';
import { Form, Input, Row, Col, Divider, DatePicker, Badge, Select } from 'antd';
import FormPage from '../../../../../../components/pages/FormPage2';
import kdKemas from '../../../../../../assets/kode_kemas.json';

const { Option } = Select;
const DefModel = {
    no_bc11: '',
    tgl_bc11: moment().format('YYYYMMDD'),
}

export default () => {
    const [model, setModel] = useState({ ...DefModel });

    const handleDateChange = (name, date, dateString) => {
        const formattedDate = moment(date).format('YYYYMMDD');
        setModel((prevState) => ({ ...prevState, [name]: formattedDate }));
    };

    const options = kdKemas.map(item => ({
        ...item,
        name: `${item?.value}, ${item?.name.trim()}`
    }));

    return (
        <FormPage
            url={'api/v1/tps_export/export_out'}
            formValid={false}
            model={model}
            layout="vertical"
            setModel={setModel}
            formSize="default"
            title={"Form Export Out"}
            emptyModel={DefModel}
            callbackPath={"/tps/export/export_out"}
            onSaveEvent={(model) => {
                const {
                    _id, no_bc11, tgl_bc11
                } = model;
                return {
                    _id, no_bc11, tgl_bc11
                }
            }}
        >
            <Row gutter={16} style={{ width: '50%' }}>
                <Divider>HEADER</Divider>
                <Col span={12}>
                    <Form.Item label="KD DOK">
                        <Input autoComplete="off" defaultValue={4} readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="CALL SIGN">
                        <Input autoComplete="off" defaultValue={model?.awb_id?.flight_record_id?.airline_id?.code} readOnly />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label="KD TPS">
                        <Input autoComplete="off" defaultValue={model?.kode_tps} readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="TGL TIBA">
                        <Input autoComplete="off" defaultValue={moment(model?.awb_id?.flight_record_id?.flight_date_str).format("DD-MMM-YYYY")} readOnly />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label="NM ANGKUT">
                        <Input autoComplete="off" defaultValue={model?.awb_id?.flight_record_id?.airline_id?.name} readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="KD GUDANG">
                        <Input autoComplete="off" defaultValue={model?.kode_gudang_ekspor} readOnly />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label="NO NOVY FLIGHT">
                        <Input autoComplete="off" defaultValue={model?.awb_id?.flight_number} readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="REF NUMBER">
                        <Input autoComplete="off" defaultValue={`${model?.kode_tps}${moment().format('YYMMDD')}${numeral(model?.no_urut + 1).format('000000')}`} readOnly />
                    </Form.Item>
                </Col>

                <Divider>DETAIL</Divider>
                <Col span={8}>
                    <Form.Item label="NO HAWB">
                        <Input autoComplete="off" defaultValue={model?.hawb_number} readOnly />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label="KD DOK INOUT">
                        <Input autoComplete="off" defaultValue={model?.kd_dok_inout} readOnly />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label="NO IJIN TPS">
                        <Input autoComplete="off" defaultValue={model?.no_izin_tps} readOnly />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item label="TGL HAWB">
                        <Input autoComplete="off" defaultValue={!!model?.hawb_date ? moment(model.hawb_date).format("YYYYMMDD") : null} readOnly />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label="NO DOK INOUT">
                        <Input autoComplete="off" defaultValue={model?.no_dok_inout} readOnly />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label="TGL IJIN TPS">
                        <Input autoComplete="off" defaultValue={`${model?.tgl_izin_tps}`} readOnly />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label="NO MASTER BL AWB">
                        <Input autoComplete="off" defaultValue={model?.mawb_number} readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="TGL DOK INOUT">
                        <Input autoComplete="off" defaultValue={moment(model?.tgl_dok_inout).format('DD-MMM-YYYY')} readOnly />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label="TGL MASTER BL AWB">
                        <Input autoComplete="off" defaultValue={!!model?.mawb_date ? moment(model.mawb_date).format('YYYYMMDD') : null} readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="WK INOUT">
                        <Input autoComplete="off" defaultValue={moment().format('YYYYMMDDHHmmss')} readOnly />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label="ID CONSIGNEE">
                        <Input autoComplete="off" defaultValue={model?.exportir_npwp} readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="KD SAR ANGKUT INOUT">
                        <Input autoComplete="off" defaultValue="4" readOnly />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label="CONSIGNEE">
                        <Input autoComplete="off" defaultValue={model?.exportir_name} readOnly />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label="BRUTO">
                        <Input autoComplete="off" defaultValue={model?.awb_id?.weight} readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="KOLI">
                        <Input autoComplete="off" defaultValue={model?.awb_id?.pieces} readOnly />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label={
                        <>
                            NO BC11
                            <Badge count="edit" style={{ backgroundColor: '#52c41a', marginLeft: 8 }} />
                        </>
                    } name={'no_bc11'}>
                        <Input maxLength={6} autoComplete="off" />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label={
                        <>
                            TGL BC11
                            <Badge count="edit" style={{ backgroundColor: '#52c41a', marginLeft: 8 }} />
                        </>
                    }>
                        <DatePicker
                            style={{ width: '100%' }}
                            format="DD-MMM-YYYY"
                            value={moment(model?.tgl_bc11)}
                            onChange={(date, dateString) => handleDateChange("tgl_bc11", date, dateString)}
                        />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label="PEL MUAT">
                        <Input autoComplete="off" defaultValue={`${model?.awb_id?.origin?.country}${model?.awb_id?.origin?.code}`} readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="PEL BONGKAR">
                        <Input autoComplete="off" defaultValue={`${model?.awb_id?.dest?.country}${model?.awb_id?.dest?.code}`} readOnly />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label="KODE KANTOR">
                        <Input autoComplete="off" defaultValue={`${model?.kode_kantor}`} readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="NO DAFTAR PABEAN">
                        <Input autoComplete="off" defaultValue={`${model?.peb_number}`} readOnly />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item
                        label={"KODE KEMAS"}
                        name={"kode_kemas"}>
                        <Select
                            showSearch
                            placeholder=""
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option?.children?.toLowerCase().includes(input.toLowerCase())
                            }
                            open={false}
                            onDropdownVisibleChange={() => false}
                        >
                            {options.map((option) => (
                                <Option key={option.value} value={option.value} label={option.name}>
                                    {option.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="TGL DAFTAR PABEAN">
                        <Input autoComplete="off" defaultValue={`${model?.peb_date}`} readOnly />
                    </Form.Item>
                </Col>
            </Row>
        </FormPage>
    )
}