import { grey } from '@ant-design/colors'
import { Button, Divider, Form, Input, Typography } from 'antd'
import React, { Fragment, useCallback } from 'react'
import { RiLoginCircleLine, RiUser3Line, RiLockLine } from 'react-icons/ri'
import { useDispatch } from 'react-redux';
import { login } from '../../redux/reducer/auth';
export default ({ apps }) => { // eslint-disable-line
    const dispatch = useDispatch();
    const onFinished = useCallback((values) => {
        dispatch(login({ ...values, app: apps }));
    }, []) // eslint-disable-line
    return (
        <Fragment>
            <div className={`loginBackground login-${apps.toLowerCase()}`} />
            <div style={{ display: 'flex', flexDirection: 'row', width: '100vw', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
                <Form
                    title={`Login To ${apps}`}
                    layout='vertical'
                    style={{ width: 420, border: `solid 1px ${grey[1]}`, paddingInline: 16, paddingBlock: 24, background: 'white', borderRadius: 10 }}
                    labelAlign='left'
                    onFinish={onFinished}
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                >
                    <Typography.Title type='secondary' level={3} style={{ textAlign: 'center' }}>Login TPS Online</Typography.Title>
                    <Divider style={{ margin: '20px 0' }} />
                    <div style={{ paddingInline: 24 }}>
                        <Form.Item name={'user'} label="Username" rules={[{ required: true, message: 'Username Required' }]}>
                            <Input autoFocus placeholder='Username' prefix={<RiUser3Line color={grey[2]} />} />
                        </Form.Item>
                        <Form.Item name={'password'} label="Password" rules={[{ required: true, message: 'Password Required' }]}>
                            <Input.Password placeholder='Password' prefix={<RiLockLine color={grey[2]} />} />
                        </Form.Item>
                        <Button
                            icon={<RiLoginCircleLine style={{ marginInline: 5 }} />}
                            block
                            htmlType="submit"
                            type='primary'
                        >
                            Login
                        </Button>
                    </div>
                </Form>
            </div>
        </Fragment>
    )
}