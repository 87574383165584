import numeral from "numeral";

export const Version = 1;
export const MajorVersion = 0;
export const MinorVersion = 0;
export const buildNumber = 2;
export const AppVersion = `${Version}.${MajorVersion}.${MinorVersion}.${numeral(
  buildNumber
).format("0000")}`;

export const updateLog = [
  {
    version: "1.0.0.2",
    update: [
      "Add tps document verify and report awb"
    ]
  },
  {
    version: "1.0.0.1",
    update: ["Beta Version"],
  },
];
